import { KeyboardShortcut } from '@hudl/uniform-ui-components';
import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="April 2022" subnav="release-notes">
      <div id="April2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Spring has sprung and so have the new updates across the design
          system! We launched our new contribution process to empower other
          squads to bring fresh design and dev ideas to Uniform. Check out the
          latest changes to our web, mobile, and design libraries below.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Fixed>
                Discrepancies between{' '}
                <Link href="/components/modules/code">Module components</Link>{' '}
                documentation and production.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.17.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Added>
                Link to{' '}
                <Link href="https://hudl.slack.com/archives/C03BQKV6T24">
                  #uniform-contributions
                </Link>{' '}
                and the{' '}
                <Link href="https://hudl-jira.atlassian.net/jira/software/c/projects/UNI/boards/354">
                  Uniform Contributions backlog
                </Link>{' '}
                in the{' '}
                <Link href="https://github.com/hudl/uniform-ui-components/blob/main/CONTRIBUTING.md">
                  Contributing docs
                </Link>
                .
              </Added>
              <Added>
                <code>xsmall</code> size option to{' '}
                <Link href="/components/spinner/design">Spinner</Link>.
              </Added>
              <Added>
                New Ethernet and WiFiDisconnected icons to our{' '}
                <Link href="/components/icons/technology/design">
                  technology set
                </Link>
                .
              </Added>
              <Improved>
                The size of our WiFi icons in the{' '}
                <Link href="/components/icons/technology/design">
                  technology set
                </Link>
                .
              </Improved>
              <Improved>
                Alignment of text in first and last segments in a{' '}
                <Link href="/components/modules/segmented-bar/design">
                  Segmented Bars
                </Link>
                .
              </Improved>
              <Improved>
                Size of the "View More/Less" button on long{' '}
                <Link href="/components/modules/bar-list/design">
                  Bar Lists
                </Link>{' '}
                and{' '}
                <Link href="/components/modules/number-list/design">
                  Number Lists
                </Link>
                .
              </Improved>
              <Fixed>
                Keyboard issue on{' '}
                <Link href="/components/modules/design#Choosing%20a%20Visualization">
                  Module Data Visualization components
                </Link>{' '}
                where the <KeyboardShortcut>space</KeyboardShortcut> press to
                toggle items was interfering with the play and pause controls on
                video players in the same screen.
              </Fixed>
              <Fixed>
                Rounding bug with{' '}
                <Link href="/components/modules/segmented-bar/code">
                  Segmented Bar
                </Link>
                .
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.5.0 (Planned for May 15th)"
              versionUrl="https://github.com/hudl/jarvis/releases"
            />
            <div className={styles.changesList}>
              <Added>
                <code>xsmall</code> size option to{' '}
                <Link href="/components/spinner/design">Spinner</Link>.
              </Added>
              <Added>
                New Ethernet and WiFiDisconnected icons to our{' '}
                <Link href="/components/icons/technology/design">
                  technology set
                </Link>
                .
              </Added>
              <Improved>
                The size of our WiFi icons in the{' '}
                <Link href="/components/icons/technology/design">
                  technology set
                </Link>
                .
              </Improved>
              <Fixed>
                Rounding bug with{' '}
                <Link href="/components/modules/segmented-bar/design">
                  Segmented Bar
                </Link>
                .
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Added>
                A new{' '}
                <Link href="https://www.figma.com/file/sSUBwS1wyBfwm4DomKdiQ3/Icon-Contribution-Template">
                  Icon Contribution Template
                </Link>{' '}
                for new or updated Icons!
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
